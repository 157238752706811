:root {
    --main-text-color: #374353;
    color: var(--main-text-color);
    --white: #FFFFFF;

    --primary: #019ee4;
    --gray98: #F5F5F5;
    --gray95: #F2F2F2;
    --gray90: #E6E6E6;
    --gray85: #D9D9D9;
    --gray75: #BFBFBF;
    --gray70: #B3B3B3;
    --gray60: #999999;
    --gray55: #8C8C8C;
    --gray50: #808080;
    --gray45: #737373;
    --gray40: #666666;
    --gray30: #4D4D4D;
    --gray25: #404040;
}

body {
    margin: 0;
    font-family: Pretendard, -apple-system, sans-serif;
}

* {
    overscroll-behavior: none;
    font-family: Pretendard, -apple-system, sans-serif;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}

a, img {
    -webkit-user-drag: none;
}

*::selection {
}

button {
    all: unset;
    display: inline-block;
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
}

.blackToMainTextColor {
    filter: invert(23%) sepia(27%) saturate(474%) hue-rotate(175deg) brightness(96%) contrast(92%);
}